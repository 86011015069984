<template>
    <v-container fluid>
        <PageLayout>
            <div class="d-flex justify-space-between mb-3">
                <h1>{{ $t('notifications') }}</h1>
                <div
                    class="d-flex align-center"
                    :class="!($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly) ? 'text-right' : 'text-right'"
                >
                   <span @click="getDevices" style="cursor: pointer">
                        <v-icon left small>
                            mdi-monitor
                        </v-icon>
                        {{ $t('devices') }}
                   </span>
                    <span @click="readNotifications" class="ml-5 bold" :style="this.countUnreadNotifications > 0 ? 'font-weight: 500;cursor: pointer' : 'cursor: pointer'">
                        {{ $t('mark_as_read') }}
                        <v-icon right small style="font-weight: 500;" class="bold">
                            mdi-check-all
                        </v-icon>
                   </span>
                </div>
            </div>

            <div
                v-if="no_data"
                class="mx-auto"
            >
                <v-list-item-content>
                    <div class="text-overline mb-4">
                        <v-img src="img/no/storage.png"></v-img>
                    </div>
                </v-list-item-content>
            </div>
            <div v-else>
                <v-list three-line>
                    <template v-for="(item, index) in notificationItems">
                        <v-list-item
                            :key="item.uuid"
                            @click="notificationClick(item)"
                            :to="{
                                name: item.data.route,
                                params: item.data.params
                            }"
                        >
                            <v-list-item-avatar>
                                <v-icon
                                    :class="(item.read ? 'grey' : 'primary')"
                                    class=" lighten-1"
                                    dark
                                >
                                    mdi-card-text
                                </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title v-html="item.title"></v-list-item-title>
                                <v-list-item-subtitle v-html="item.message"></v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-list-item-action-text v-text="item.date"></v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </v-list>
                <v-card-actions class="justify-center" v-if="(nextLink===true && loading===false) ? true : false">
                    <v-btn
                        color="primary"
                        outlined
                        class="elevation-0 "
                        @click="nextItems"
                    >
                        {{ $t('download_more') }}
                    </v-btn>
                </v-card-actions>
            </div>
        </PageLayout>

        <v-dialog
            v-model="dialog_device"
            scrollable
            max-width="540px"
        >
            <v-card>
                <v-card-title class="px-2 mx-0 text-right">
                    <v-icon @click="dialog_device = false;" left>mdi-close-circle</v-icon>
                    {{ $t('tethered_devices') }}

                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="my-5">
                    <v-row>
                        <v-col cols="12" class="text-justify">
                            {{ $t('tethered_devices_text') }}
                        </v-col>
                    </v-row>
                    <v-list two-line>

                        <v-list-item
                            v-for="(device, i) in devices"
                            :key="device.id"
                            :class="(i === 0 ? 'rounded-xl rounded-b-0' : (i === devices.length -1 ? 'rounded-xl rounded-t-0' : ''))"
                            class="py-3 my-0 topic_background"
                        >


                            <v-list-item-content v-text="device.user_agent">


                            </v-list-item-content>

                            <v-list-item-action>
                                <v-btn icon @click="deleteDevice(device)">
                                    <v-icon color="grey lighten-1">mdi-delete</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>


                    </v-list>
                    <v-btn block
                           rounded
                           class="mt-5"
                           color="primary"
                           @click="checkNotification"
                    >
                        {{ $t('receive_notifications_on_device') }}
                    </v-btn>
                </v-card-text>


            </v-card>
        </v-dialog>

    </v-container>
</template>
<script>
import {mapActions, mapGetters} from "vuex"
import {getMessaging, getToken} from "firebase/messaging";

export default {
    name: 'Notifications',
    inject: [
        'forceRerenderSidebar'
    ],
    data() {
        return {
            tab: 0,
            loading: false,
            language: null,
            page: 1,
            pageCount: 0,
            perPage: 25,
            totalVisiblePag: 7,
            totalNotifications: 0,
            notificationItems: [],
            no_data: false,
            dialog_device: false,
            devices: [],
            nextLink: true,
            system_filters: [
                {'title': 'Все контакты', 'count': '10'},
                {'title': 'Мои клиенты', 'count': '0'},
                {'title': 'Избранное', 'count': '7'},
            ],
            Additional: [],
            group1: true,
            group2: true,
            filter_show: false,
        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang', 'countUnreadNotifications']),
        languages() {
            return this.listLanguages
        },
    },
    async mounted() {
        this.language = this.languages[this.tab]
        await this.getNotifications()
    },
    methods: {
        ...mapActions(['setCountUnreadNotifications']),
        async getNotifications(page) {
            this.loading = true
            let params = {}
            if (page !== undefined) {
                params.page = page;
            }
            if (this.perPage !== undefined) {
                params.perPage = this.perPage;
            }
            // if (this.lang) {
            //     params.language = this.lang
            // }
            await this.$http
                .get("admin/notification", {
                    params: params,
                })
                .then(res => {
                    this.notificationItems = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalNotifications = res.body.meta.total
                    this.pageCount = res.body.meta.last_page

                    if (this.pageCount === this.page) {
                        this.nextLink = false;
                    } else {
                        this.nextLink = true;
                    }


                    if (this.notificationItems.length <= 0) {
                        this.no_data = true;
                    } else {
                        this.no_data = false;
                    }
                })
                .catch(err => {
                    this.notificationItems = []
                    this.totalNotifications = 0
                    this.$toastr.error(this.$t('failed_to_get_notifications'))

                })
                .finally(end => {
                    this.loading = false
                })
        },
        async nextItems() {
            var _this = this;

            this.loading = true;
            let params = {};

            params.page = this.page + 1;
            params.sortBy = this.sortBy;
            params.sortDir = this.sortDir ? 'asc' : 'desc';
            params.perPage = this.perPage;
            if (this.lang) {
                params.language = this.lang
            }


            await this.$http
                .get("admin/notification", {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress2 = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    let data = res.body.data;
                    data.forEach((item) => {
                        this.notificationItems.push(item)
                    })

                    this.page = res.body.meta.current_page
                    this.totalNotifications = res.body.meta.total
                    this.pageCount = res.body.meta.last_page

                    if (this.pageCount === this.page) {
                        this.nextLink = false;
                    } else {
                        this.nextLink = true;
                    }
                    data = [];
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_notifications'))
                })
                .finally(end => {
                    this.loading = false
                })


        },
        async readNotifications() {
            this.loading = true
            await this.$http
                .get("admin/notification/read")
                .then(res => {
                    this.notificationItems.forEach((item) => {
                        item.read = 1;
                    });
                    this.setCountUnreadNotifications(0)
                    this.forceRerenderSidebar();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('error_reading_notification'))
                })
                .finally(end => {
                    this.loading = false
                })
        },

        notificationClick(item) {
            if (item.read === 0) {
                this.notificationRead(item)
            } else if (item.data && item.data.type && item.data.route) {
                if ((item.data.type === 'invite' || item.data.type === 'invoice' || item.data.type === 'tracking_updates' || item.data.type === 'invitation_accepted') && item.data.route !== '') {
                    this.$router.push({
                        name: item.data.route,
                    })
                }
            }
        },
        async notificationRead(item) {
            this.loading = true
            var formData = new FormData()
            await this.$http
                .put(`admin/notification/${item.uuid}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                })
                .then(res => {
                    item.read = 1;
                    //Обновим колличество не прочитаных в хронилище
                    let count = this.countUnreadNotifications - 1;
                    this.setCountUnreadNotifications(count)
                    this.forceRerenderSidebar();
                    // Если есть определенные переменные для перенаправления
                    if (item.data && item.data.type && item.data.route) {
                        if ((item.data.type === 'invite' || item.data.type === 'invoice' || item.data.type === 'tracking_updates' || item.data.type === 'invitation_accepted') && item.data.route !== '') {
                            this.$router.push({
                                name: item.data.route,
                            })
                        }
                    }

                })
                .catch(err => {
                    this.$toastr.error(this.$t('notify_has_not_been_updated'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async getDevices() {
            this.loading = true
            let params = {}
            if (this.lang) {
                params.language = this.lang
            }
            await this.$http
                .get("admin/device", {
                    params: params,
                })
                .then(res => {
                    this.devices = res.body.data
                    this.dialog_device = true;

                })
                .catch(err => {
                    this.devices = []
                    this.$toastr.error(this.$t('failed_to_get_list_devices'))

                })
                .finally(end => {
                    this.loading = false
                })
        },
        async deleteDevice(item) {
            if (confirm(this.$t('delete_device'))) {
                var _this = this
                this.loading = true
                await this.$http
                    .delete(`admin/device/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('device_has_been_deleted'))
                        this.getDevices()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('device_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },

        checkNotification() {
            var _this = this
            if (!("Notification" in window)) {
                console.log("This browser does not support desktop notification");
            } else if (Notification.permission === "granted") {
                console.log("Notification.permission granted");
                // If it's okay let's create a notification
                //var notification = new Notification("Hi there 1!")
                this.listenChannelFCM()
            } else if (Notification.permission !== 'denied' || Notification.permission === "default") {
                Notification.requestPermission(function (permission) {
                    console.log("User accepts, let's create a notification");
                    // If the user accepts, let's create a notification
                    if (permission === "granted") {
                        //var notification = new Notification("Hi there 2!")
                        _this.listenChannelFCM()
                    }
                })
            }
        },
        listenChannelFCM() {
            const messaging = getMessaging();
            getToken(messaging, {vapidKey: process.env.VUE_APP_FCM_VAPID_KEY})
                .then((token) => {
                    if (token) {
                        // Send the token to your server and update the UI if necessary
                        //console.log('Send the token to your server ', token);
                        console.log('Send the token to your server');
                        this.saveNotificationToken(token)
                    } else {
                        console.log('No registration token available. Request permission to generate one.');
                    }
                })
                .catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                });
        },
        saveNotificationToken(token) {
            const params = {
                token: token,
                type: 'browser'
            }
            this.$http
                .post("admin/device", params)
                .then((res) => {
                    this.getDevices();
                    console.log('Successfully saved notification token!')
                })
                .catch((err) => {
                    console.log('Error: could not save notification token')
                })
        },
    }
}
</script>
<style scoped>
.topic_background {
    border: 1px solid #f2f4f5;
}

.topic_title {
    font-weight: 400;
    font-size: 19px;
    color: #000
}
</style>
